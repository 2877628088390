import { Component, input, InputSignal, output, OutputEmitterRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ItemInfo } from '@core/models/navigation-item.model';

import { setRestrictedAccessRoute } from '../../guards/navigationLink.guard';
import { CompanyTypeBasedRoutePipe } from '../../pipes/company-type-based-route.pipe';

@Component({
  selector: 'ume-main-nav-menu-panel',
  templateUrl: './main-nav-menu-panel.component.html',
  styleUrls: ['./main-nav-menu-panel.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    RouterLinkActive,
    CompanyTypeBasedRoutePipe,
    RouterLink,
  ],
})
export class MainNavMenuPanelComponent {
  public items: InputSignal<ItemInfo[]> = input.required();
  public triggerCustomEvent: OutputEmitterRef<void> = output();

  @ViewChild('menu', { static: true }) menu!: MatMenu;

  constructor(private _router: Router) {}

  public handleClick(item: ItemInfo, event: MouseEvent): void {
    if (item.payment) {
      event.preventDefault();
      this._router.navigate(['/', 'plans', 'comparison']);
      return;
    }

    if (item.disabled) {
      event.preventDefault();
      this._redirectToRestrictedUserRights(item);
    }

    if (item.customEvent) {
      if (item.disabled || item.disabledReason) {
        this._redirectToRestrictedUserRights(item);
        return;
      }

      this.triggerCustomEvent.emit();
    }
  }

  private _redirectToRestrictedUserRights(item: ItemInfo): void {
    const label: string | string[] = setRestrictedAccessRoute(item.menuLabel);
    if (!label || label === '') {
      this._router.navigate(['/', 'restricted-user-rights']);
      return;
    }

    this._router.navigate([
      '/',
      ...(Array.isArray(label) ? label : [label]),
      'restricted-user-rights',
    ]);
    return;
  }
}
